<template>

  <div class="section-content">
    <div style="flex: 1">
      <hb-report
        :key="report_key"
        v-if="view || report_id"
        :report_id="report_id"
        :report_type="view"
        show_title
        :actions_panel="actions_panel"
        right_click
        row_click
        show_search
      ></hb-report>
    </div>
  </div>
</template>

<script type="text/babel">
import HbReport from '../assets/HummingbirdReportViewer.vue';
import { mapGetters } from "vuex";

export default {
        name: "ReportViewer",
        data() {
            return {
                report_key: 0,
                view: '',
                report_id: '',
                report_library: [
                    { name: "Active Tenants", type: "active-tenants", key: 'active_tenants',  description: "Rent Roll." },
                    { name: "Active Leads", type: "active-leads", key: 'active_leads', description: "Rent Roll." },
                    { name: "Retired Leads", type: "retired-leads", key: 'retired_leads', description: "This report gives details about the retired leads and the person who has retired." },
                    { name: "Spaces", type: "spaces", key: 'spaces', description: "Rent Roll." },
                    { name: "Rent Roll", type: "rent-roll", key: 'rent_roll', description:  "Rent Roll." },
                    { name: "Rent Roll with all Spaces", type: "rent-roll-with-all-spaces", key: 'rent_roll_with_all_spaces', description: "The Rent Roll with All Spaces report provides detailed information about your properties, tenants and their associated rents for all space status." },
                    { name: "Payments and Credits", type: "payments-and-credits", key: 'payments_and_credits', description: 'The Payments report allows you to track and verify payment and credit details.'},
                    { name: "Invoices", type: "invoices", key: 'invoices', description:  "Rent Roll." },
                    { name: "Coverage Activity", type: "coverage_activity", key: 'coverage_activity', description:  "Coverage activity report" },
                    { name: "Failed Payments", type: "failed-payments", key: 'failed_payments', description: "Rent Roll." }, 
                    { name: "Vacancy Report", type: "vacancy-report", key: 'vacancy_report',  description: "Rent Roll." },
                    { name: "Payments Activity", type: "payments", key: 'payments', description: "Rent Roll." },
                    { name: "Accounts Receivable",  key: 'accounts_receivable', type: "accounts-receivable", description: "Rent Roll." },
                    { name: "Walk Through Audit", type: "walk-through-audit", key: 'walk_through_audit', description: "Rent Roll." },
                    { name: "Prepaid Rent", type: "prepaid-rent",  key: 'prepaid_rent', description: "Rent Roll." },
                    { name: "Unapplied Payments", type: "unapplied-payments",  key: 'unapplied_payments', description: "This shows open payments for a customer that haven't been applied to an invoices. This a liabilities report." },
                    { name: "Sales Tax Payments", type: "sales-tax-payments", key: 'sales_tax_payments', description: "Sales Tax Payments report shows all the payments allocated to Sales Tax for all your charges." },
                    { name: "Sales Tax Charges", type: "sales-tax", key: 'sales_tax_charges', description: "Rent Roll." },
                    { name: "Unit Overlock or Unlock", type: "overlock", key: 'overlock', description: "Rent Roll." },
                    { name: "Insurance", type: "insurance",  key: 'insurance', description: "The Insurance report includes a detailed list of insured tenants with their premium costs and coverage amounts." },
                    { name: "Merchandise Sales", type: "merchandise-sales", key: 'merchandise_sales', description:  "The Merchandise Sales report provides a list of merchandise you've sold at your property." },
                    { name: "Property Merchandise", type: "property-products", key: 'property_products', description:  "The Property Merchandise report provides a list of all merchandises for selected property." },
                    { name: "Autopay", type: "autopay", key: 'autopay', description:  "The AutoPay report displays the payments that are using AutoPay, and the next bill date on the lease. This report does not provide historical payments." },
                    { name: "Auction List", type: "auction-list",  key: 'auction_list', description: "Create a list of all the upcoming and past due auctions." },
                    { name: "Space Details", type: "space-deatils",  key: 'space_details', description: "The Space Details report provides fine details about every space at your properties. Use this report to view information such as how many of your spaces are vacant, promotions tied to certain spaces, spaces with delinquent tenants, and more." },
                    { name: "Tenant Emails", type: "tenant-emails", key: 'tenant_emails', description: "The Tenant Emails report will show you a list of all current and past tenants alongside their emails. Use this report to gather your tenants' emails in one place to quickly send them an email."},
                    { name: "Processed Cash Transactions", type: "cash-payments",  key: 'cash_payments', description: "The Processed Cash Transactions report provides a list of all successful cash transactions at your properties." },
                    { name: "Processed Credit Card Transactions", type: "credit-card-payments",  key: 'credit_card_payments', description: "The Processed Credit Card Transactions report provides a list of all successful credit card transactions at your properties. The report shows transaction details such as the type of card used, the last four digits of the card, the amount charged, and more." },
                    { name: "Processed ACH Transactions", type: "ach-payments",  key: 'ach_payments', description: "The Processed ACH Transactions report provides a list of all successful ACH transactions at your properties. The report shows transaction details such as when the transaction was processed, the last four digits of the account, the amount charged, and more." },
                    { name: "Voided Payments", type: "void-payments",  key: 'void_payments', description: "The Void Payments report tracks all voided payments. Use this report to review how many payments your manager has voided." },
                    { name: "Refunds", type: "refunds",  key: 'refunds', description: "The Refunds Report allows you to view all of the refunds you've given out. Use this report to keep track of how much money you have refunded to tenants." },
                    { name: "Protection or Insurance Status", type: "insurance-status",  key: 'insurance_status', description: "The Protection/Insurance Status report gives you the insurance status of all current tenants. Use this report to easily view which of your tenants are enrolled in insurance and which are not." },
                    { name: "Discounts and Promotions", type: "discounts-and-promotions",  key: 'discounts_and_promotions', description: "The Discounts and Promotions report lets you see how many discounts have been given out during a specified time frame. use this report to calculate your cost to convert prospects into tenants." },
                    { name: "Active Tenant Directory", type: "active-tenant-directory",  key: 'active_tenant_directory', description: "The Active Tenant Directory provides information for all your active tenants as of today. Use this report to quickly access your tenant's information such as their name, space number, address, phone number, status, and total past due." },
                    { name: "Move-out with Reason", type: "move-out",  key: 'move_out', description: "This is a report where we can see why tenants are moving out, so that we can get feedback on how to retain tenants." },
                    { name: "Completed Tasks", type: "completed-tasks", key: 'completed_tasks', description: "This report lists all completed tasks" },
                    { name: "Incomplete Tasks", type: "incomplete-tasks", key: 'incomplete_tasks', description: "The Completed Tasks report shows every completed task for all the people using Hummingbird at your company. Use this report to verify if store managers are completing all the daily tasks necessary to effectively manage your properties." },
                    { name: "All Tasks", type: "all-tasks", key: 'all_tasks', description: "The All Tasks report shows all tasks for all the people using Hummingbird at your company. Use this report to verify if store managers are completing all the daily tasks necessary to effectively manage your properties." },
                    { name: "Scheduled Move-out with Reason", type: "move-out-tasks", key: 'move_out_task', description: "This report lists all Intended Move Out tasks" },
                    { name: "Tenant Billing", type: "tenant-billing",  key: 'tenant_billing', description: "The Tenant Billing report provides billing-related details about all current and future tenants. Use this report to view information such as billing type, rental amount, next billing date, paid through date, and more." },
                    { name: "Voided Invoices", type: "void-invoices",  key: 'void_invoices', description: "The Voided Invoices report provides a list of the invoices you've voided at your properties. Use this report to see when an invoice was voided, the amount that was voided, who voided the invoice, and more." },
                    { name: "Bad Debt", type: "bad-debt",  key: 'bad_debt', description: "The Bad Debt report provides a list of all the products you have written off. Use this report to measure how much money you've lost due to forgiving charges." },
                    { name: "Lead Activity - Modified Date", type: "lead-activity",  key: 'lead_activity', description: "View all leads and their activities, driven by the Lead Modified Date to reflect the latest updates." },
                    { name: "Pending Move-In Activity", type: "pending-move-in-activity",  key: 'pending_move_in_activity', description: "View a record of your current and past pending move-ins from XPS. Use this report to better understand which leads get converted to determine the effectiveness of your XPS call center. " },
                    { name: "Paused Delinquencies", type: "delinquency_pauses",  key: 'delinquency_pauses', description: "Report of paused delinquencies." },
                    { name: "Delinquencies", type: "delinquency",  key: 'delinquency', description: "Report of delinquencies." },
                    { name: "Failed Delinquency Actions", type: "delinquency_failed_actions",  key: 'delinquency_failed_actions', description: "Report of failures in the delinquency processes." },
                    { name: "Cleaning Deposit Status", type: "cleaning-deposit-status",  key: 'cleaning_deposit_status', description: "The report gives a view of cleaning deposits collected and refunded during the auction process." },
                    { name: "Auction Details", type: "auction-details",  key: 'auction_details', description: "The report gives a view of all auctions, status of the auction and bidder details for a given period." },
                    { name: "Tenant Additional Contacts", type: "additional-contacts", key: 'additional_contacts', description: "The Tenant Additional Contacts report will show you a list of all current tenants alongside their additional contacts. Use this report to gather your tenant's additional contacts in one place."},
                    { name: "Inter-Property Payments", type: "inter-property-payment",  key: 'inter_property_payment', description: "The report outlines the payments taken at a property that were for charges at another property." },
                    { name: "Inter-Property Payment Refunds", type: "inter-property-payment-refunds",  key: 'inter_property_payment_refunds', description: "The report outlines the refunds of payments taken at a property that were for charges at another property." },
                    { name: "property_phone_calls", type: "property_phone_calls", key: 'property_phone_calls', description: "This report generates all the phone call records of selected properties"},
                    { name: "Active National Accounts", type: "active_national_accounts", key: 'active_national_accounts', description: "Active National Accounts in System"},
                    { name: "Processed Direct Debit Transactions", type: "direct-debit-payments",  key: 'direct_debit_payments', description: "The Processed Direct Debit Transactions report provides a list of all successful Direct Debit transactions at your properties. The report shows transaction details such as when the transaction was processed, the last four digits of the account, the amount charged, and more." },
                    { name: "Processed EFTPOS Transactions", type: "eftpos-payments",  key: 'eftpos_payments', description: "The Processed EFTPOS Transactions report provides a list of all successful EFTPOS transactions at your properties. The report shows transaction details such as when the transaction was processed, the type of card used, the amount charged, and more." },          
                    { name: "Reverted Payment Cycles", type: "reverted-payment-cycles", key: 'reverted_payment_cycles', description:  "The report shows the details of all the payment cycles that were either reverted or cancelled." },
                    { name: "Reversed Move-outs", type: "reversed-move-outs", key: 'reversed_move_outs', description:  "The report shows the details of all the move-outs that have been reversed." },
                    { name: "Free Insurance", type: "free_insurance", key: 'free_insurance', description:  "The report shows the details of tenants enrolled in free insurance." },
                    { name: "Refund Pending Tenants", type: "refund-pending-tenants", key: 'refund_pending_tenants', description:  "The report displays details of tenants with pending or failed refunds." },
                    { name: "Lead Activity - Created Date", type: "lead-activity-created-date",  key: 'lead_activity_created_date', description: "View all leads and their activities, driven by the Lead Created Date to reflect when they were first created." },

                ],
                actions_panel: ['advanced', 'filters', 'export', 'bulk_edit', 'columns', 'save'],
                report_config: {
                    "invoices-payments": {
                        report_name: 'invoices_payments',
                        report_title: 'Invoices Payments'
                    },
                    units: {
                        report_name: 'units',
                        report_title: 'Spaces'
                    },
                    payments: {
                        report_name: 'payments',
                        report_title: 'Payments'
                    },
                    "failed-payments": {
                        report_name: 'failed_payments',
                        report_title: 'Failed Payments'
                    },
                    "merchandise-sales": {
                        report_name: 'merchandise_sales',
                        report_title: 'Merchandise Sales'
                    },
                    "property-products": {
                        report_name: 'property_products',
                        report_title: 'Property Merchandise'
                    },
                    "autopay": {
                        report_name: 'autopay',
                        report_title: 'Autopay'
                    },
                    invoices: {
                        report_name: 'invoices',
                        report_title: 'Invoices'
                    },
                    "invoice-lines": {
                        report_name: 'invoice_lines',
                        report_title: 'Billing'
                    },
                    tenants: {
                        report_name: 'tenants',
                        report_title: 'Tenants'
                    },
                    leads: {
                        report_name: 'leads',
                        report_title: 'Leads'
                    },
                    maintenance: {
                        report_name: 'maintenance',
                        report_title: 'Maintenance'
                    },
                    "payment-methods": {
                        report_name: 'payment_methods',
                        report_title: 'Payment Methods'
                    },
                    products: {
                        report_name: 'products',
                        report_title: 'Products'
                    },
                    interactions: {
                        report_name: 'interactions',
                        report_title: 'Interactions'
                    },
                    activity: {
                        report_name: 'activity',
                        report_title: 'Activity'
                    },
                    tasks: {
                        report_name: 'tasks',
                        report_title: 'Tasks'
                    },
                    triggers: {
                        report_name: 'triggers',
                        report_title: 'Triggers'
                    },
                    promotions: {
                        report_name: 'promotions',
                        report_title: 'Promotions'
                    },
                    checklists: {
                        report_name: 'checklists',
                        report_title: 'Checklists'
                    },
                    documents: {
                        report_name: 'documents',
                        report_title: 'Documents'
                    },
                    applications: {
                        report_name: 'applications',
                        report_title: 'Applications'
                    },
                    auction_list: {
                        report_name: 'auction_list',
                        report_title: 'Auction List'
                    },
                    free_insurance: {
                        report_name: 'free_insurance',
                        report_title: 'Free Insurance'
                    },
                }
            }
        },
        components:{
            HbReport
        },
        computed: {
         ...mapGetters({
               getDynamicRunParam: 'reportStore/geDynamicRunParam',
           })
       },
        async created(){
            // BCT: Get report store data from local storage
            let runReportState = localStorage.getItem('run-report-state');
            let dynamicRunParam = this.getDynamicRunParam;

            runReportState ? this.$store.commit("reportStore/setDynamicRunParam", JSON.parse(runReportState)) : '';
            dynamicRunParam ? this.$store.commit("reportStore/setDynamicRunParam", dynamicRunParam) : '';

            this.report_id = this.$route.params.report_id;
            
            // if(!this.report_config[this.$route.params.report_type]) this.$router.replace('/404');

            if(this.$route.params.report_type){
              let report = this.report_library.find(rl => this.$route.params.report_type === rl.type);
              console.log("report_name", report)
              this.view = report.key;
              if(this.$route.params.report_type === 'lead-activity') {
                  this.actions_panel = ['advanced', 'filters', 'export', 'columns', 'save']
              }
            }

            //  BCT: Clear local storage for report data.
            localStorage.removeItem('run-report-state');

        },
        destroyed(){
        },
        filters:{

        },
        reportName(){
            if(this.$route.params.report_type){
                let report = this.report_library.find(rl => this.$route.params.report_type === rl.type);
                return report.name;
            }
            return 'Report';
        },


        methods:{
            viewLease(data){
                this.$router.push('/leases/' + data.lease_id);
            }
        },

        watch:{
        }

    }
</script>
<style scoped>

</style>
